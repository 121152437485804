import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../../components/Layouts/base"
import { FormPKW } from "../../components/FormPKW"

export default function FormOrderFrame() {
  return (
    <Layout>
      <Helmet defer>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <FormPKW
        frame
        title="Оформите карту"
        dataLayerName="shortPersonalForm"
        orderNum="7"
        longForm
      />
    </Layout>
  )
}
